.page {
    overflow-y: hidden;
}

.page-background {
    opacity: 10%;
    overflow: hidden;
}

.description-background {
    width: 100%;
    position: fixed;
    top: calc(25vh - 10em);
    z-index: 1;
}

.description-container {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.individual-description-container {
    text-align: center;
}

.hidden-element {
    display: none;
}

.exit-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-bottom: 1em;
}

.exit-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-color: black;
    border-style: solid;
    border-radius: 50%;
    width: 2em;
    height: 2em;
}

.exit-button {
    font-size: 1.5em;
}

.exit-circle:hover {
    cursor: pointer;
    opacity: 50%;
}

.participant-photo-description {
    width: 20em;
    margin-left: auto;
    margin-right: auto;
}

.participant-photo-description:hover {
    cursor: pointer;
}

.participant-description {
    text-align: justify;
    font-size: 1.2em;
}

.header-container {
    display: flex;
}

#headerSeaImage {
  width: 100%;
  height: 8em;
  position: absolute;
}

#aquaCliqueLogo {
  position: absolute;
  top: 0.4em;
  left: 0.8em;
  height: 3.4em;
}

.app-name-title {
    width: 100%;
    text-align: center;
    margin-top: .30em;
    color: white;
    z-index: 1;
    text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.6);
}

.content-container {
  margin-left: 2em;
  margin-right: 2em;
}


.construction-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 4em;
}

.construction-description-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 1.5em;
}

.construction-image {
    width: 32em;
}

#construction-title {
    margin-bottom: 0;
    color: #808080;
    font-weight: 800;
    font-size: 2.1em;
}

.construction-description {
    margin-bottom: 0;
    text-align: justify;
    color: #696969;
    font-size: 1.35em;
}

.about-container {
    margin-bottom: 2em;

}

.subtitle {
    text-align: center;
    color: #1B4EB2;
    margin-top: 0;
}

.about-description {
    color: #696969;
    font-size: 1.15em;
    text-align: justify;
}

.participants-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 3em;
}

.hint {
    font-size: .8em;
}

.participant-photo {
    width: 14em;
}

.participant-photo:hover {
    cursor: pointer;
}

.form-link {
    text-decoration: underline;
    color: inherit;
}

.form-link:hover {
    cursor: pointer;
    opacity:0.6;
}

.supporters-container {
    width: 100%;
    text-align: center;
    box-shadow: 0px 10px 5px #e5e5e5, 0px -3px 5px #e5e5e5;
    padding-top: 1.2em;
    padding-bottom: 1.2em;
}

.supporters {
    max-width: 300px;
    max-height: 300px;
    padding: 10px;
}

.supporters:hover {
    cursor: pointer;
}

.footer-container {
    display: flex;
    justify-content: center;
    color: white;
    background-color: #1B4EB2;
}

.copyright-description-container {
    display: flex;
    align-items: center;
}

.copyright-description {
    margin-top: 2em;
    margin-bottom: 2em;
    margin-left: .4em;
}









@media (min-width: 1390px) {

    .content-container {
        width: 60em;
        margin-left: auto;
        margin-right: auto;
    }

}


@media (max-width: 1010px) {

    .participant-photo {
        width: 13em;
    }

}



@media (max-width: 970px) {

    .construction-image {
        margin-top: 1em;
    }

    .construction-container {
        flex-direction: column;
    }

    .construction-image {
        margin-left: auto;
        margin-right: auto;
    }

    #construction-title {
        text-align: center;
    }

    .construction-description-container {
        margin-left: 0;
    }

    .participants-container {
        flex-direction: column;
    }

    .hint {
        text-align: center;
    }

    .participant-photo {
        width: 22em;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 2em;
    }

}



@media (max-width: 650px) {
    .participant-photo {
        width: 19em;
    }

    .participant-photo-description {
        width: 16em;
    }

    .participant-description {
        max-height: 17em;
        overflow-y: scroll;
    }
}




@media (max-width: 573px) {
    .construction-image {
        width: 24em;
    }
}




@media (max-width: 434px) {
    .construction-image {
        width: 19em;
    }

    .participant-photo {
        width: 16em;
    }
}




@media (max-width: 397px) {

    .description-container {
        width: 85%;
        padding-top: 1em;
    }

    .exit-circle {
        width: 1.5em;
        height: 1.5em;
    }

    .exit-button {
        font-size: 1em;
    }

    .participant-photo-description {
        width: 15em;
    }

    .participant-description {
        font-size: 1.1em;
    }



    #construction-title {
        font-size: 1.8em;
    }

    .construction-description {
        font-size: 1.2em;
    }

    .copyright-description {
        font-size: .7em;
    }


}




















































/*.App {*/
  /*text-align: center;*/
/*}*/

/*.App-logo {*/
  /*height: 40vmin;*/
  /*pointer-events: none;*/
/*}*/

/*@media (prefers-reduced-motion: no-preference) {*/
  /*.App-logo {*/
    /*animation: App-logo-spin infinite 20s linear;*/
  /*}*/
/*}*/

/*.App-header {*/
  /*background-color: #282c34;*/
  /*min-height: 100vh;*/
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-items: center;*/
  /*justify-content: center;*/
  /*font-size: calc(10px + 2vmin);*/
  /*color: white;*/
/*}*/

/*.App-link {*/
  /*color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
  /*from {*/
    /*transform: rotate(0deg);*/
  /*}*/
  /*to {*/
    /*transform: rotate(360deg);*/
  /*}*/
/*}*/
